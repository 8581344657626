import React from "react";
import PropTypes from "prop-types";

import {
	Box,
	Typography,
	Select, MenuItem,
	Checkbox,
	IconButton,
	Grid,
	Divider
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

import TemplateInputField from "./TemplateInputField.js";
import { StrategyContext } from "../../../context/StrategyContext.js";

class TemplateInputSignal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"template_signal" : props.value,
			"is_new_signal" : !props.value.signal_type,
		};

		this.selectIndicator = this.selectIndicator.bind(this);
		this.selectSignal = this.selectSignal.bind(this);
		this.updateField = this.updateField.bind(this);
		this.generateParametersInputs = this.generateParametersInputs.bind(this);
		this.renderSelectType = this.renderSelectType.bind(this);
		this.renderSelectIndicator = this.renderSelectIndicator.bind(this);
		this.renderSelectSignal = this.renderSelectSignal.bind(this);
		this.updateDirection = this.updateDirection.bind(this);
		this.updateOptional = this.updateOptional.bind(this);
		this.updateSignalType = this.updateSignalType.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"className" : PropTypes.string,
		"indicator_pool" : PropTypes.object.isRequired,
		"value" : PropTypes.object.isRequired,
		"onChange" : PropTypes.func.isRequired,
		"onDone" : PropTypes.func
	};

	componentDidUpdate(prev_props) {
		const prev = prev_props.value;
		const curr = this.props.value;

		if (JSON.stringify(curr) !== JSON.stringify(prev)) {
			this.setState({
				"template_signal" : curr,
				"is_new_signal" : !curr.signal_type
			});
		}
	}

	render() {
		const { granularity_boundaries } = this.context;
		const { indicator_pool } = this.props;
		const { template_signal, is_new_signal } = this.state;
		const indi_title = template_signal.indicator_title;
		const sig_title = template_signal.title;
		const optional = template_signal.optional ? template_signal.optional : false;

		const has_indicator_pool = indicator_pool && Object.keys(indicator_pool).length;
		const has_indicator = template_signal.indicator_title && template_signal.indicator_title !== "";

		const indicator_options = Object.keys(indicator_pool);

		let direction_value = "both";
		if (template_signal.direction && "value" in template_signal.direction) direction_value = template_signal.direction.value;

		const is_ready = template_signal.signal_type && indi_title && sig_title && indi_title !== "" && sig_title !== "";
		return (
			<Box sx={{"padding" : "15px"}}>
				<Typography
					color="font.main"
					variant="body1"
					sx={{"marginRight" : "30px"}}
				>{is_new_signal ? "New" : "Edit"} Signal</Typography>

				<Box className="flex-row-space-between">
					<Box className="flex-row-space-between" sx={{"alignItems" : "center"}}>

					{
						is_new_signal &&
						<Box className="flex-row-space-between">
							<Typography
								color="font.main"
								variant="body2"
								sx={{"marginRight" : "10px"}}
							>Signal Type:</Typography>

							<Select
								value={template_signal.signal_type || ""}
								onChange={this.updateSignalType}
								displayEmpty={true}
								renderValue={v => v === "N" ? "Entrance" : v === "X" ? "Exit" : "Select a Type"}
								size="small"
								sx={{
									"typography":"body2",
									"color" : "font.main",
									"marginRight" : "20px"
								}}
							>
								<MenuItem sx={{"color" : "font.main"}} value={"N"}>Entrance</MenuItem>
								<MenuItem sx={{"color" : "font.main"}} value={"X"}>Exit</MenuItem>
							</Select>
						</Box>
					}
						{/* Long/Short Dropdown */}
						<Typography
							color="font.main"
							variant="body2"
							sx={{"marginRight" : "10px"}}
						>Direction:</Typography>
						<Select
							value={direction_value}
							onChange={this.updateDirection}
							size="small"
							sx={{
								"typography":"body2",
								"color" : "font.main",
								"marginRight" : "20px"
							}}
						>
							<MenuItem sx={{"color" : "font.main"}} value={"both"}>Either</MenuItem>
							<MenuItem sx={{"color" : "font.main"}} value={"L"}>Only Long</MenuItem>
							<MenuItem sx={{"color" : "font.main"}} value={"S"}>Only Short</MenuItem>
						</Select>

						<Typography color="font.main" variant="body2">Optional:</Typography>
						<Checkbox
							checked={optional}
							color="primary"
							onChange={this.updateOptional}
							sx={{"marginRight" : "20px"}}/>
					</Box>


					{
						is_ready &&
						<IconButton
							onClick={() => {
								this.props.onChange(this.state.template_signal, this.props.onDone);
							}}
						>
							<SaveIcon color="success"/>
						</IconButton>
					}
				</Box>

				<Divider sx={{"width" : "100%", "margin" : "10px 0px 10px 0px"}} />

				<Box
					className="flex-row-space-between"
					sx={{
						"justifyContent" : "space-evenly",
						"marginTop" : "15px"
					}}
				>
					{/* Indicator Dropdown */}
					<Box>
						<Typography color="font.main" variant="body1">Indicator</Typography>
						{
							has_indicator_pool &&
							<Select
								value={template_signal?.indicator_title || ""}
								size="small"
								sx={{"typography":"body2", "color" : "font.main"}}
								displayEmpty={true}
								renderValue={v => v === "" ? "Select an Indicator" : v}
								onChange={this.selectIndicator}
							>
								{
									indicator_options.map(k => {
										return (
											<MenuItem
												key={k}
												value={k}
												sx={{"color" : "font.main"}}
											>{k}</MenuItem>
										);
									})
								}
							</Select>
						}
					</Box>

					{/* Signal Dropdown */}
					<Box>
						<Typography color="font.main" variant="body1">Signal</Typography>
						<Select
							value={template_signal?.title || ""}
							size="small"
							sx={{"typography":"body2", "color" : "font.main"}}
							displayEmpty={true}
							renderValue={v => v === "" ? "Select a Signal" : v}
							onChange={this.selectSignal}
						>
							{
								has_indicator && indicator_pool[indi_title] &&
								indicator_pool[indi_title].signals && indicator_pool[indi_title].signals.length &&
								indicator_pool[indi_title].signals.map(s => {
									return (
										<MenuItem
											key={s.title}
											value={s.title}
											sx={{"color" : "font.main"}}
										>{s.title}</MenuItem>
									);
								})
							}
						</Select>
					</Box>

					<Box>
						<TemplateInputField
							label="Granularity"
							more_info="The amount of time that goes into a single candlestick."
							datatype="duration"
							field_name="Granularity"
							boundaries={granularity_boundaries}
							value={template_signal.granularity}
							onChange={this.updateField} />
					</Box>
				</Box>

				{
					is_ready &&
					<Box>
						<Typography
							color="font.main"
							variant="body1"
						>Parameters</Typography>
						<Divider
							sx={{
								"width" : "100%",
								"margin" : "10px 0px 10px 0px"
							}}
						/>
						<Grid
							container
							spacing={0}
							sx={{"justifyContent" : "space-evenly"}}
						>
							{
								has_indicator_pool && has_indicator &&
								this.generateParametersInputs()
							}
						</Grid>
					</Box>
				}
			</Box>
		);
	}

	updateOptional(evt) {
		const { template_signal } = this.state;
		const { checked } = evt.target;
		template_signal.optional = checked ? true : false;
		
		this.setState({template_signal});
		this.props.onChange(template_signal);
	}

	updateDirection(evt) {
		const { value } = evt.target;
		const { template_signal } = this.state;
		
		switch(value) {
			case "both":
				template_signal.direction = { "options" : ["L", "S"] }
				break;
			case "L":
			case "S":
				template_signal.direction = { value };
				break;
			default:
				return;
		}

		this.setState({ template_signal });
		this.props.onChange(template_signal);
	}

	renderSelectType(value) {
		return value === "" ? "Select a Type" : value;
	}

	renderSelectIndicator() {
		const indi = this.state.template_signal.indicator_title;

		if (!indi || indi === "") return "Select an Indicator";
		else return indi;
	}

	renderSelectSignal() {
		const sig = this.state.template_signal.title;

		if (!sig || sig === "") return "Select a Signal";
		else return sig;
	}

	selectIndicator(evt) {
		const { indicator_pool } = this.props;
		const template_signal = { ...this.state.template_signal };
		const { value } = evt.target;

		template_signal.indicator_title = value;
		template_signal.title = indicator_pool[value].signals[0].title;
		template_signal.parameters = {};
		for (const p of indicator_pool[value].parameters) {
			template_signal.parameters[p.title] = { "value" : p["default"] };
		}
		this.setState({ template_signal });
	}

	selectSignal(evt) {
		const template_signal = { ...this.state.template_signal };
		const { indicator_title } = template_signal;
		const { indicator_pool } = this.props;
		const signal_pool = indicator_pool[indicator_title].signals
		const { value } = evt.target;

		let chosen_signal = signal_pool.filter(s => s.title === value);
		chosen_signal = chosen_signal.length ? chosen_signal[0] : {};
		
		template_signal.title = chosen_signal.title;
		this.setState({ template_signal });
	}

	/**
	 * Watches updates to the granularity and parameters values.
	 * 
	 * @param {*} field_name 
	 * @param {*} value 
	 */
	updateField(field_name, value) {
		let { template_signal } = { ...this.state };

		if (field_name === "Granularity") {
			template_signal[field_name.toLowerCase()] = value;
		} else if (field_name in template_signal.parameters) {
			template_signal.parameters[field_name] = value;
		} else {
			return;
		}

		this.setState({template_signal});
		this.props.onChange(template_signal);
	}

	updateSignalType(evt) {
		const { template_signal } = this.state;
		const { value } = evt.target;

		template_signal["signal_type"] = value;
		this.setState({ template_signal });
	}

	generateParametersInputs() {
		const indi_title = this.state.template_signal.indicator_title;
		const { parameters } = this.props.indicator_pool[indi_title];
		if (!parameters || parameters.length === 0) return [];

		return parameters.map(p => {
			const { title, datatype, min, max, step } = p;
			// NOTE: The key "i" is being mapped to "float", not "integer". This is intentional.
			const datatype_map = { "i" : "float", "f" : "float", "d" : "duration", "b" : "boolean" };
			const this_param = this.state.template_signal.parameters[title];

			return (
				<Grid
					key={title}
					item
					xs={4}
					sx={{"border" : "1px solid lightgrey", "margin" : "5px"}}
				>
					<TemplateInputField
						label={title}
						more_info="Lorem Ipsum"
						field_name={title}
						value={ this_param ? { ...this_param } : null }
						datatype={datatype_map[datatype]}
						boundaries={{abs_min : min, abs_max : max, min_step : step}}
						onChange={this.updateField} />
				</Grid>
			);
		});
	}
cw
	getGranularityBoundaries() {
		return {};
	}
}

export default TemplateInputSignal;
