import React from "react";
import PropTypes from "prop-types";

import {
	Box, Button, Typography
} from "@mui/material";


import { StrategyContext } from "../../context/StrategyContext.js";

class DashboardStart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.handleAdd= this.handleAdd.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"topic" : PropTypes.string,
		"match" : PropTypes.object,
		"history" : PropTypes.object,
		"location" : PropTypes.object
	};

	render() {
		const { topic } = this.props;
		const is_group = topic !== "strategy";

		return (
			<Box className="dashboard-box flex-col-center">
				<Typography
					color="font.main"
					variant="h1"
					sx={{
						"marginTop" : "20vh"
					}}
				>Select or Add a { is_group ? "Group" : "Strategy" } to Start</Typography>

				<Button
					color="primary"
					variant="contained"
					sx={{
						"color":"font.button",
						"marginTop" : "5vh",
					}}
					onClick={this.handleAdd}
				>Create New {is_group ? "Group" : "Strategy"}</Button>
			</Box>
		);
	}

	handleAdd() {
		const { setContext } = this.context;
		const { topic } = this.props;
		const is_group = topic !== "strategy";

		const k = is_group ? "clicked_add_group" : "clicked_add_strategy";
		setContext({[k] : true});
	}
}

export default DashboardStart;
