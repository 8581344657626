import React from "react";

export const defaultStrategyContext = {
	// Stores all "branches": groups, strategies, simulations, and positions
	"groups" : [],
	"strategies" : {},
	"simulations" : {},
	"positions" : {},

	"selected_branch" : null, // groups, strategies, simulations, or positions
	"selected_leaf" : null, // the corresponding id.
	"selected_node_id" : "",
	"expanded_node_ids" : [],

	// Status booleans used for loading pages.
	"is_simulating" : false,

	"indicator_pool" : {},
	"security_pool" : [],
	// Current restrictions on granularities.
	"granularity_boundaries" : {
		"abs_min" : 1000 * 60 * 60, // 15 min
		"abs_max" : 1000 * 60 * 60 * 24, // 1 wk
		"min_step" : 1000 * 60 * 15 // 15 min
	},

	"is_loaded" : false,
	"is_loading" : false,
	"loading_failed" : false,
	"loading_msg" : null,
	// This information is persisted from the AccountContext. Important for determining permissions
	"membership_tier" : null,
	"unsaved_changes" : false,

	"simulation_data" : {
		"security" : null,
		"start_time" : null,
		"stop_Time" : null
	},

	"clicked_add_group" : false,

	"selected_position" : null,
	"selected_simulation" : null,
	"graph_start_time" : null,
	"graph_stop_time" : null,
	"collapse_list" : false,
	"sim_page_size" : 10,
	"strat_page_size" : 10,
	"metric_sort_col" : 3,
	"metric_sort_direction" : "desc",
	"current_strategy_max_pages" : 1,
	"current_strategy_page" : 1,
};

export const StrategyContext = React.createContext(defaultStrategyContext);
