import React from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/material";

import log from "loglevel";
import async from "async";

// TODO: Check if there have been any major updates to this library.
import { createChart } from "lightweight-charts";

import {
	getColCount,
	getColAsSeries,
	localizeUnixTime,
	delocalizeUnixTime
} from "../../lib/assemble-parser.js";
import { StrategyContext } from "../../context/StrategyContext.js";

/**
 * TODO: Need an easy way of also plotting indicators...
 */
class StrategyCharts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"chart_width" : 500,
			"start_col" : 13, // TODO: Magic number code smell.
			"chart" : null,
			"series" : null,
			"timestamps" : null,
			"position_start_time" : null,
			"position_stop_time" : null,
			"graph_start_time" : null,
			"graph_stop_time" : null
		};

		this.intakeAndNormalizePropData = this.intakeAndNormalizePropData.bind(this);
		this.createChart = this.createChart.bind(this);
		this.clearChart = this.clearChart.bind(this);
		this.plotData = this.plotData.bind(this);
		this.centerChart = this.centerChart.bind(this);
		this.checkChartUpdates = this.checkChartUpdates.bind(this);
		this.handleVisibleUpdate = this.handleVisibleUpdate.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"chart_id" : PropTypes.string.isRequired,
		"price_data" : PropTypes.array,
		"indicator_data" : PropTypes.string,
		"only_price" : PropTypes.bool,
		"graph_start_time" : PropTypes.number, // Start of focus window.
		"graph_stop_time" : PropTypes.number // End of focus window.
	};

	/**
	 * is_price_chart = true
	 * 	overlay_data is an array with all tof the CSVs needed to build the Chart
	 *  	only price data from first CSV will be used, the remaining CSV will just be used for their indicator data
	 * is_price_chart = false
	 * 	overlay_data is a single CSV that will be used 
	 * 
	 * 
	 * is is_price_chart only reflects if the price data will be used for the props array?
	 * 	assumes all price data is the same and same granularities
	 */
	componentDidMount() {
		this.setState({"is_rebuilding" : true});
		async.series([
			this.intakeAndNormalizePropData,
			this.createChart,
			this.plotData,
			this.centerChart
		], err => {
			if (err) {
				log.debug(err);
			}
			this.setState({"is_rebuilding" : false});
		});
	}

	componentDidUpdate(prev_props) {
		const { is_rebuilding } = this.state;
		const a = JSON.stringify(prev_props);
		const b = JSON.stringify(this.props);

		if (is_rebuilding) return;
		else if (a !== b) {
			this.setState({"is_rebuilding" : true});
			// Rebuilds the charts.
			async.series([
				this.intakeAndNormalizePropData,
				this.clearChart,
				this.plotData,
				this.centerChart,
			], (err, results) => {
				if (err) {
					log.debug(err);
				}

				this.setState({"is_rebuilding" : false});
			});
		} else if (this.checkChartUpdates()) {
			this.setState({"is_rebuilding" : true});
			this.centerChart((err) => {
				this.setState({"is_rebuilding" : false});
			});
		}
	}


	render() {
		const { chart_id } = this.props;
		const { chart_width } = this.state;

		return (
			<Box
				id={chart_id}
				sx={{
					"borderWidth" : "1px 1px 1px 1px",
					"borderStyle" : "solid",
					"borderColor" : "secondary.main",
					"width" : `${chart_width}px !important`
				}}
			/>
		);
	}

	/**
	 * Very inneficient strategy
	 * for each csv, parse out each column individually. This means that if there are 5 columns, the entire document will be read 5 full times to parse out each column.
	 */
	intakeAndNormalizePropData(cb) {
		const { price_data, indicator_data, only_price } = this.props;
		const { start_col } = this.state;

		let data_series = [];

		// TODO: Find a better mechanism than [price_data, indicator_data, price_only]
		if (price_data !== undefined) {
			for (const csv of price_data) {
				if (data_series.length === 0) { // Grabs price data.
					const opens = getColAsSeries(csv, 5);
					const highs = getColAsSeries(csv, 6);
					const lows = getColAsSeries(csv, 7);
					const closes = getColAsSeries(csv, 8);

					let arr = [];
					for (let i = 0; i < opens.length; i += 1) {
						arr.push({
							"time" : opens[i].time,
							"open" : opens[i].value,
							"high" : highs[i].value,
							"low" : lows[i].value,
							"close" : closes[i].value,
						});
					}

					data_series.push(arr);
					//data_series.push(getColAsSeries(csv, 8)); // TODO: Confirm price data exists first, CSVs need to be validated.
					
					if (only_price) break;
				}

				// This code adds the signal overlays to the price data.
				let col_count = getColCount(csv);
				for (let i = start_col; i < col_count; i += 1) {
					data_series.push(getColAsSeries(csv, i));
				}
			}
		} else if (indicator_data !== undefined) {
			let col_count = getColCount(indicator_data);
			for (let i = start_col; i < col_count; i += 1) {
				data_series.push(getColAsSeries(indicator_data, i));
			}
		} else {
			return;
		}

		if (data_series.length === 0) return;
		const timestamps = data_series[0].map(v => v.time);
		this.setState({ data_series, timestamps }, cb);
	}

	createChart(cb) {
		const { chart_id } = this.props;
		const { chart_width } = this.state;

		const chart = createChart(chart_id, {
			"width" : chart_width, "height" : 300
		});

		chart.applyOptions({
			"timeScale" : {
				"visible" : true,
				"timeVisible" : true,
				"secondsVisible" : false,
				"fixLeftEdge" : true,
				"fixRightEdge" : true,
				"tickMarkFormatter" : (time, tickMarkType, locale) => {
					const d = new Date(time * 1000);
					return d.toLocaleDateString(locale);
				}
			}
		});

		setTimeout(() => {
			chart.timeScale().subscribeVisibleTimeRangeChange(this.handleVisibleUpdate);
		}, 50);

		this.setState({ chart }, cb);
	}

	handleVisibleUpdate(new_range) {
		const { setContext } = this.context;
		const { is_rebuilding } = this.state;

		if (!new_range || is_rebuilding) return;

		setContext({
			"graph_start_time" : delocalizeUnixTime(new_range.from),
			"graph_stop_time" : delocalizeUnixTime(new_range.to),
		});
	}

	clearChart(cb) {
		const { chart, series } = this.state;

		if (chart && series) {
			for (const s of series) {
				chart.removeSeries(s);
			}
		}

		this.setState({ "series" : null }, cb);
	}

	plotData(cb) {
		const { chart, data_series } = this.state;

		const color_options = [
			"#937080",
			"#6493a5",
			"#776396",
			"#988e70",
			"#654240",
			"#556189",
			"#416059"
		];

		const new_chart_series = [];
		for (const i in data_series) {
			const ds = data_series[i];
			const idx = new_chart_series.length;

			// Perhaps replace this with a bar / candle chart?
			let new_series = null;
			if (!!ds[0].open) {
				new_series = chart.addCandlestickSeries({
					"title" : "",
					"thinBars":true,
					"openVisible":true
				});
			} else {
				new_series = chart.addLineSeries({
					"color" : color_options[idx],
					"lineWidth" : 2,
				});
			}
			

			new_series.setData(ds);
			new_series.applyOptions({
				"priceFormat" : {
					"type" : "price",
					"precision" : 5,
					"minMove" : 0.00001
				}
			});

			new_chart_series.push(new_series);

			// Ensures there are enough colors without failing.
			if (new_chart_series.length >= color_options.length) {
				break;
			}
		}

		this.setState({ "series" : new_chart_series }, cb);
	}

	/**
	 * Out of all of the data in the CSV, this centers the chart on the
	 * timestamps specified by graph_start_time and graph_stop_time.
	 *
	 */
	centerChart(cb) {
		const {
			selected_position, graph_start_time, graph_stop_time, position_data
		} = this.context;
		const { chart, series, timestamps } = this.state;

		chart.timeScale().unsubscribeVisibleTimeRangeChange(this.handleVisibleUpdate);
		const done = () => {
			setTimeout(() => {
				chart.timeScale().subscribeVisibleTimeRangeChange(this.handleVisibleUpdate);
				return cb(null);
			}, 20);
		};

		/**
		 * Checks if the provided focus window is a valid window within
		 * the CSV's timestamps.
		 *
		 * If it is not valid, just zoom out to show the entire
		 * dataset.
		 */
		if (!series || !timestamps || !timestamps.length) return done();
		else if (!graph_start_time && !graph_stop_time) {
			chart.timeScale().setVisibleRange({
				"from" : localizeUnixTime(timestamps[0]),
				"to" : localizeUnixTime(timestamps[timestamps.length - 1])
			});
			return done();
		}

		/**
		 * Sets the chart's time range to the focus window.
		 *
		let from_time = undefined;
		for (let i in timestamps) {
			if (!from_time && graph_start_time < timestamps[i]) {
				from_time = timestamps[i];
			} else if (from_time && graph_stop_time < timestamps[i]) {
				let time_range = timestamps[i] - from_time;
				let time_margin = time_range * 1;
				chart.timeScale().setVisibleRange({
					"from" : from_time - time_margin,
					"to" : timestamps[i] + time_margin
				});
				break;
			}
		}*/

		chart.timeScale().setVisibleRange({
			"from" : localizeUnixTime(graph_start_time),
			"to" : localizeUnixTime(graph_stop_time)
		});


		/**
		 * Highlights the focus widnow within the chart.
		 * TODO: Find a better way to do this.
		 * TODO: Make the direction of the arrows dependent on is_long
		 *
		 * https://tradingview.github.io/lightweight-charts/docs/api/interfaces/SeriesMarker
		 */

		const marker_scale = 0.5;
		let markers = [];
		const new_pos = [ ...position_data ];
		new_pos.sort((a, b) => {return a.open_time - b.open_time});
		for (const p of new_pos) {
			markers.push({
				"time" : parseInt(localizeUnixTime(p.open_time / 1000)),
				"text" : "Enter",
				"position" : p.is_long ? "belowBar" : "aboveBar",
				"color" : "#673ab7",
				"shape" : p.is_long ? "arrowUp" : "arrowDown",
				"size" : marker_scale,
			});
			markers.push({
				"time" : parseInt(localizeUnixTime(p.close_time / 1000)),
				"text" : "Exit",
				"position" : p.is_long ? "aboveBar" : "belowBar",
				"color" : "#673ab7",
				"shape" : p.is_long ? "arrowDown" : "arrowUp",
				"size": marker_scale
			});
		}
		series[0].setMarkers(markers);

		this.setState({
			"position_start_time" : selected_position.open_time,
			"position_stop_time" : selected_position.close_time,
			graph_start_time, graph_stop_time
		}, done);

	}

	checkChartUpdates() {
		const { selected_position, graph_start_time, graph_stop_time } = this.context;
		const {
			position_start_time,
			position_stop_time,
			"graph_start_time" : from,
			"graph_stop_time" : to
		} = this.state;


		let is_same = true;
		is_same = is_same && selected_position.open_time === position_start_time;
		is_same = is_same && selected_position.close_time === position_stop_time;
		is_same = is_same && from === graph_start_time;
		is_same = is_same && to === graph_stop_time;

		return !is_same;
	}

}

export default StrategyCharts;
