import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import {
	Button, Container, Box, Paper, Typography, IconButton
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// Used for storing relevant strategy data across components.
import {
	assembleStrategy,
	getMetricsBySimulationId,
	isolateSimulation,
	buildPositionsCsv
} from "../../../lib/strategy-ops.js";
import { StrategyContext } from "../../../context/StrategyContext.js";
import PositionTable from "./PositionTable.js";
import MetricsGrid from "./MetricsGrid.js";
import AssembleCharts from "./AssembleCharts.js";
import StrategyBuilder from "../StrategyBuilder.js";

import "./simulation-view.css";
import { msToDuration } from "../../../lib/duration-ops.js";

class SimulationView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"security_data" : [],
			"indicator_data" : [],
			"positions" : [],
			"unique_granularities" : [],
			"selected_position" : null,
			"simulation_data" : {
				"security_id" : 0,
				"start_time" : 0,
				"stop_time" : 0,
				"strategy_json" : {}
			},
			"show_snap_up" : false,
			"old_sim" : null
		};

		this.assembleData = this.assembleData.bind(this);
		this.verifySimulation = this.verifySimulation.bind(this);
		this.getSimulationData = this.getSimulationData.bind(this);
		this.downloadPositions = this.downloadPositions.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	static contextType = StrategyContext;

	/**
	 * The intention for the StrategyView widget is that it only needs to
	 * receive the strategy object, security, and time frame as an input, and
	 * it can then determine what data it needs to request from the server.
	 */
	static propTypes = {
		"className" : PropTypes.string,
		"simulation_id" : PropTypes.number,
		"match" : PropTypes.object,
		"history" : PropTypes.object,
		"location" : PropTypes.object
	};

	/**
	 * Overriden methods.
	 */
	componentDidMount() {
		this.componentDidUpdate();

		const tmp = setInterval(() => {
			const el = document.querySelector(".strategy-view");
			if (!el) return;

			console.log("Baxinga");

			el.scrollIntoView();
			clearInterval(tmp);
			window.addEventListener("scroll", this.handleScroll, true);
		}, 100);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}


	/**
	 * Called on mount:
	 *
	 * Intended action during condition:
	 * 		Calls getSimulationData once the simulation ID changes.
	 * 		Checks props first, then falls back on URL?
	* 		Shouldn't it always use the URL?
	 */
	componentDidUpdate(prev_props) {
		let { old_sim } = this.state;
		let cur_sim = null;

		// Sets booleans for how simulation_id is being passed.
		const is_direct = !!this.props.simulation_id;
		const is_route = !!this.props.match?.params?.simulation_id;

		// Confirms that simulation_id is provided.
		if (is_direct) {
			cur_sim = this.props["simulation_id"];
		} else if (is_route) {
			cur_sim = this.props?.match?.params["simulation_id"];
		} else {
			log.error("Invalid props provided to SimulationView.");
			return;
		}

		// Check if the simulation has changed, and update simulation data if so.
		if (cur_sim !== old_sim) {
			this.setState({ "old_sim" : cur_sim}, () => {
				this.getSimulationData(cur_sim);
			});
		}
	}

	render() {
		const {
			security_pool, collapse_list, setContext, selected_leaf
		} = this.context;
		const { simulation_id } = this.props;
		const {
			assembled_data,
			metrics,
			unique_granularities,
			positions,
			simulation_data,
			show_snap_up
		} = this.state;

		const selected_security = security_pool?.filter(s => s.security_id === simulation_data.security_id)[0];
		if (!selected_security) return (<div />);
		const is_demo = !!this.props.simulation_id;
		const sim_duration = simulation_data.stop_time - simulation_data.start_time;
		const start_time_fmt = new Date(simulation_data.start_time).toLocaleString()
		const stop_time_fmt = new Date(simulation_data.stop_time).toLocaleString()
		const created_at_fmt = new Date(selected_leaf?.created_at || 0).toLocaleString()

		return (
			<Box
				className="strategy-view flex-col-center"
				sx={{"width" : simulation_id ? "100%" : undefined}}
			>
				<Box className="strategy-details">
					<Typography
						color="font.main"
						variant={"h2"}
					>Simulation Details</Typography>

					<Container
						className="flex-row-space-around"
						component={Paper}
						sx={{"padding" : "10px"}}
					>
						<Box className="flex-row-space-between simulation-details-text">
							<Box className="flex-col-space-between">
								<Typography
									color="font.main"
									variant={"body1"}
								>Security:</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
								>Start Date:</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
								>Stop Date:</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
								>Duration:</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
								>Simulated At:</Typography>
							</Box>
							<Box className="flex-col-space-between">
								<Typography
									color="font.main"
									variant={"body1"}
									textAlign="right"
								>{selected_security.symbol}</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
									textAlign="right"
								>{start_time_fmt}</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
									textAlign="right"
								>{stop_time_fmt}</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
									textAlign="right"
								>{msToDuration(sim_duration)}</Typography>
								<Typography
									color="font.main"
									variant={"body1"}
									textAlign="right"
								>{created_at_fmt}</Typography>
							</Box>
						</Box>
						<Box
							className={is_demo ? "flex-col-center" : "flex-col-space-between"}
						>
							{
								!is_demo &&
								<Button
									onClick={this.verifySimulation}
									variant="contained"
									sx={{"color" : "font.button", "margin" : "5px"}}
								>Isolate Strategy</Button>
							}

							<Button
								onClick={this.assembleData}
								variant="contained"
								disabled={positions.length === 0}
								sx={{"color" : "font.button", "margin" : "5px"}}
							>Assemble Charts</Button>

							{
								!is_demo &&
								<Button
									onClick={this.downloadPositions}
									variant="contained"
									disabled={positions.length === 0}
									sx={{"color" : "font.button", "margin" : "5px"}}
								>Download Positions</Button>
							}
						</Box>
					</Container>
				</Box>

				<MetricsGrid
					metrics={metrics}
					simulation_data={simulation_data}
					positions={positions}
				/>

				<Typography
					color="font.main"
					variant="h2"
					sx={{
						"textAlign":"left",
						"width":"100%",
						"marginBottom" : "10px"
					}}
				>Strategy</Typography>
				<StrategyBuilder
					is_dummy={true}
					strategy={simulation_data["strategy_json"]}
				/>

				{/* The rows of graphs. */
					assembled_data && unique_granularities && simulation_data &&
					<AssembleCharts
						unique_granularities={unique_granularities}
						assembled_data={assembled_data}
						metrics={simulation_data}/>
				}

				{/* Positions table. */}
				<PositionTable />

				{
					!is_demo && 
					<IconButton
						onClick={() => {
							setContext({ "collapse_list" : !collapse_list });
						}}
						variant="contained"
						sx={{
							"position" : "fixed",
							"bottom" : "20px",
							"left" : "20px",
							"padding" : "10px",
							"backgroundColor" : "secondary.main",
							"color" : "font.button"
						}}
					>
						{
							collapse_list ?
							<ChevronRightIcon color="font.button" />
							:
							<ChevronLeftIcon color="font.button" />
						}
					</IconButton>
				}

				{
					show_snap_up &&
					<Button
						variant="contained"
						sx={{
							"position" : "fixed",
							"bottom" : "20px",
							"right" : "20px",
							"padding" : "10px",
							"color" : "font.button",
							"zIndex": "10"
						}}
						onClick={() => {
							window.scrollTo(0, 800);
						}}
					>
						Back to Top
					</Button>
				}
			</Box>
		);
	}

	/**
	 * TODO: Load context properly.
	 */
	getSimulationData(simulation_id) {
		const { setContext } = this.context;

		setContext({"is_loading" : true, "loading_msg" : "Loading Simulation Data..."});

		getMetricsBySimulationId(simulation_id, (err, res) => {
			if (err) return log.debug(err);

			const k = Object.keys(res)[0]
			const r = res[k];
			const p = r["positions"][0];

			setContext({
				"is_loading" : false,
				"loading_msg" : null,
				"position_data" : r["positions"],
				"selected_position" : p,
				"graph_start_time" : p ? parseInt(p.open_time / 1000) : null,
				"graph_stop_time" : p ? parseInt(p.close_time / 1000) : null,
			}, () => {
				this.setState({
					"metrics" : r["metrics"],
					"positions" : r["positions"],
					"simulation_data" : {
						"security_id" : r["security_id"],
						"symbol" : r["symbol"],
						"start_time" : r["start_time"],
						"stop_time" : r["stop_time"],
						"strategy_json" : r["strategy_json"],
						"created_at" : r["created_at"]
					}
				});
			});
		});
	}

	/**
	 *
	 */
	verifySimulation() {
		const { selected_leaf, strategies, groups, setContext, setPage } = this.context;

		isolateSimulation(selected_leaf.id, (err, res) => {
			if (err) return log.debug(err);

			const new_leaf = res["full_strategy"];
			const gk = `group-${new_leaf["group_id"]}`;
			if (!Object.keys(strategies).includes(gk)) {
				groups.push({
					"id" : new_leaf["group_id"],
					"type" : "custom",
					"title" : "New Custom Group" // This is the default
				});

				strategies[gk] = [];
			}

			strategies[gk].push(new_leaf);

			setContext({
				"snackbar_msg" : `Placed new strategy-${res["strategy_id"]} into group-${res["group_id"]}`,
				"snackbar_sev" : "success",
				groups, strategies
			}, () => {
				setPage(`/dashboard/group-${res["group_id"]}/strategy-${res["strategy_id"]}`);
			});
		});
	}

	/**
	 *
	 */
	assembleData() {
		const { setContext, is_loading } = this.context;
		let unique_granularities = new Set();

		if (!is_loading) setContext({"is_loading" : true, "loading_msg" : "Assembling data..."});

		const simulation_id = this.props.simulation_id || this.props.match.params["simulation_id"];

		// TODO: Make the data provider a part of the simulation_data
		assembleStrategy(simulation_id, (err, assembled_data) => {
			if (err) return log.debug(err);

			// Attempting to parse the multipart form data
			assembled_data.entrance_signals.forEach(s => {unique_granularities.add(s.granularity)});
			assembled_data.exit_signals.forEach(s => {unique_granularities.add(s.granularity)});

			setContext({"is_loading" : false, "loading_msg" : null});

			// TODO: This at least is happening, what from here?
			this.setState({
				assembled_data,
				"unique_granularities" : Array.from(unique_granularities),
			});
		});
	}

	/**
	 *
	 */
	downloadPositions() {
		const { selected_leaf } = this.context;

		buildPositionsCsv(selected_leaf["id"], (err, csv) => {
			if (err) return log.debug(err);

			// Format data for download.
			const filename = `simulation-${selected_leaf["id"]}-positions.csv`;
			const blob = new Blob([csv], {"type":"text/csv"});

			// Create new anchor tag to trigger download.
			const a = document.createElement("a");
			a.download = filename;
			a.href = window.URL.createObjectURL(blob);

			// Start the download.
			a.dispatchEvent(new MouseEvent("click", {
				"view" : window,
				"bubble" : true,
				"cancelable" : true
			}));

			// Remove the newly created anchor tag.
			a.remove();
		});
	}

	handleScroll(evt) {
		this.setState({ "show_snap_up" : window.scrollY > 800});
	}
}

export default SimulationView;
