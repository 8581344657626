import axios from "axios";

const { REACT_APP_HTTP_TIMEOUT } = process.env;

/**
 * Account Endpoints
 */

export const createAccount = (email, username, password, cb) => {
	axios({
		"method" : "PUT",
		"url" : "/account",
		"data" : { email, username, password }
	}).then(res => {
		if (res.status === 200) return cb(null, res.data.user_account);
		else if (res.data === 23505) return cb("duplicate email");
		else return cb(null);
	}).catch(err => {
		const message = err.response?.data?.message;
		if (message) return cb(message);
		 else return cb(err);
	});
};

export const updateUsername = (new_username, cb) => {
	axios({
		"method" : "PATCH",
		"url" : "/account/username",
		"data" : { new_username }
	}).then(res => {
		return cb(null);
	}).catch(cb);
}

export const updateEmail = (new_email, verification_code, cb) => {
	axios({
		"method" : "PATCH",
		"url" : "/account/email",
		"data" : { new_email, verification_code }
	}).then(res => {
		return cb(null);
	}).catch(cb);
}

export const updatePhone = (new_phone, verification_code, cb) => {
	new_phone = "+1" + new_phone.replace(/[^0-9]g/, "");

	axios({
		"method" : "PATCH",
		"url" : "/account/phone",
		"data" : { new_phone, verification_code }
	}).then(res => {
		return cb(null);
	}).catch(cb);
}

export const updatePasswordWithCode = (new_password, account_id, verification_code, cb) => {
	axios({
		"method" : "PATCH",
		"url" : "/account/password",
		"data" : { account_id, verification_code, new_password }
	}).then(res => {
		return cb(null);
	}).catch(cb);
}

export const updatePasswordWithSession = (old_password, new_password, cb) => {
	axios({
		"method" : "PATCH",
		"url" : "/account/password",
		"data" : { old_password, new_password }
	}).then(res => {
		return cb(null);
	}).catch(cb);
}


export const deleteAccount = (cb) => {
	axios({
		"method" : "DELETE",
		"url" : "/account"
	}).then(res => {
		return cb(null);
	}).catch(cb);
}

/**
 * Session Endpoints
 */
export const logIn = (username, password, cb) => {
	const encoded = btoa(`${username}:${password}`);

	axios({
		"method" : "POST",
		"url" : "/session",
		"headers" : {"Authorization" : `Basic ${encoded}`},
		"timeout" : REACT_APP_HTTP_TIMEOUT 
	}).then(res => {
			return cb(null);
	}).catch(cb);
};

export const whoAmI = (cb) => {
	axios({
		"method" : "GET",
		"url" : "/session",
	}).then(res => {
		return cb(null, res.data);
	}).catch(cb);
};

export const logOut = (cb) => {
	axios({
		"method" : "DELETE",
		"url" : "/session"
	}).then(res => {
		return cb(null);
	}).catch(cb);
};

export const acceptCookies = (cb) => {
	axios({
		"method" : "PATCH",
		"url" : "/session/cookie"
	}).then(res => {
		return cb(null);
	}).catch(cb);
};
export const updateAccountTheme = (theme, cb) => {
	axios({
		"method" : "PATCH",
		"url" : "/session/theme",
		"data" : { theme }
	}).then(res => {
		return cb(null);
	}).catch(cb);
};

export const sendVerificationToken = (medium, recipient, cb) => {
	if (medium === "sms") recipient = "+1" + recipient.replace(/[^0-9]g/, "");

	axios({
		"method" : "POST",
		"url" : "/notify",
		"data" : {
			"is_verification" : true,
			medium, recipient
		}
	}).then(res => {
		return cb(null);
	}).catch(cb);
};

export const submitSupportTicket = (content, cb) => {
	axios({
		"method" : "POST",
		"url" : "/notify/support/",
		"data" : { content }
	}).then(res => {
		return cb(null);
	}).catch(cb);
};

